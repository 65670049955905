import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import logoEdited from '../assets/images/logoEdited.png';
import RightNavIcon from '../assets/svg/RightNavIcon';

const MotionLink = motion.custom(Link);

const Welcome = () => {
	return (
		<div className='h-screen w-screen flex flex-col justify-center items-center overflow-hidden pattern-bg'>
			<img
				src={logoEdited}
				className='h-96 invisible mb-16'
				alt='Mojec-Logo'
			/>
			<MotionLink
				initial={{
					background: '#2659A2',
					borderWidth: -180,
				}}
				whileHover={{
					backgroundColor: '#13376A',
					borderWidth: 0,
				}}
				transition={{ duration: 0.5 }}
				to='home'
				className='flex justify-center items-center bg-brand-blue h-11 w-44 text-white lg:ml-24'
			>
				<span className='text-sm font-bold inline-block mr-4'>
					Enter website
				</span>
				<motion.span
					initial={{ x: -10 }}
					animate={{ x: 0 }}
					transition={{
						yoyo: Infinity,
						duration: 0.5,
					}}
				>
					<RightNavIcon color='#ffffff' />
				</motion.span>
			</MotionLink>
		</div>
	);
};

export default Welcome;
