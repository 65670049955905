import { motion } from 'framer-motion';
import mojecLogo from '../assets/images/mojecc.png';
import wall3D from '../assets/images/wall3D.jpg';
import walledited from '../assets/images/walledited.jpg';
import logoEdited from '../assets/images/logoEdited.png';
import styled from 'styled-components';

const PreloadWrap = styled.div`
	/* background: url(${walledited});
	background-repeat: no-repeat;
	background-size: cover; */
`;

const Variant = {
	initial: { scale: 2, opacity: 0.4 },
	animate: {
		scale: 1,
		opacity: 1,

		transition: {
			yoyo: Infinity,
			duration: 1,
			repeatDelay: 0.3,
		},
	},
};

const PreLoader = () => {
	return (
		<PreloadWrap className='h-screen w-screen flex justify-center items-center overflow-hidden'>
			<motion.img
				variants={Variant}
				initial='initial'
				animate='animate'
				transition='transition'
				src={logoEdited}
				className='h-28 lg:h-52'
				alt='Mojec-Logo'
			/>
		</PreloadWrap>
	);
};

export default PreLoader;
